import React from "react";
import MenuItem from "../menuItem/MenuItem"; 
import "./menu.scss";

const Menu = ({ menuOpen, setMenuOpen }) => {
  const closeMenu = () => setMenuOpen(false);

  return (
    <div className={"menu " + (menuOpen && "active")}>
      <ul>
        <MenuItem link="#intro" onClick={closeMenu}>
          Home
        </MenuItem>
        <MenuItem link="#portfolio" onClick={closeMenu}>
          Portfolio
        </MenuItem>
        <MenuItem link="#works" onClick={closeMenu}>
          Works
        </MenuItem>
        {/* <MenuItem link="#testimonials" onClick={closeMenu}>
          Testimonials
        </MenuItem> */}
        <MenuItem link="#contact" onClick={closeMenu}>
          Contact
        </MenuItem>
      </ul>
    </div>
  );
};

export default Menu;
