export const featuredPortfolio = [
    {
      id: 1,
      title: "Hibiscus Restaurant",
      img:
        "/assets/hibiscus.png",
    },
    {
      id: 2,
      title: "NHE Burritos Restaurant",
      img:
        "/assets/nhe.png",    },
    {
      id: 3,
      title: "Mountain Inn Sklarska Poreba",
      img:
          "/assets/lodge.png",    },
    // {
    //   id: 4,
    //   title: "Relax Mobile App",
    //   img:
    //     "https://cdn.dribbble.com/users/4095861/screenshots/15467417/media/d6a15c416626f12b31fa5ca1db192572.png?compress=1&resize=1200x900",
    // },
    // {
    //   id: 5,
    //   title: "Hero Web Design",
    //   img:
    //     "https://cdn.dribbble.com/users/5031392/screenshots/15467520/media/c36b3b15b25b1e190d081abdbbf947cf.png?compress=1&resize=1200x900",
    // },
    // {
    //   id: 6,
    //   title: "Banking App",
    //   img:
    //     "https://cdn.dribbble.com/users/3307260/screenshots/15468444/media/447b4501f7a145b05e11c70199a9c417.jpg?compress=1&resize=1200x900",
    // },
  ];
  
  export const webPortfolio = [
    {
      id: 1,
      title: "Mint easy Financial Predictions",
      img: "/assets/mint.png",
    },
    {
      id: 2,
      title: "Reservation manager",
      img: "/assets/invoice.png",
    },
    {
      id: 3,
      title: "Inventory management system",
      img: "/assets/chefit.png",
    },
    // {
    //   id: 4,
    //   title: "Web Relax App",
    //   img: "https://cdn.dribbble.com/users/2264844/screenshots/15463998/media/d85e92a332c41094f68e1f716884f7ce.jpg?compress=1&resize=1200x900",
    // },
    // {
    //   id: 5,
    //   title: "Web Design",
    //   img: "https://cdn.dribbble.com/users/387658/screenshots/15458608/media/e92ec1dd33dd1170ae3dc9c5272f2693.jpg?compress=1&resize=1200x900",
    // },
    // {
    //   id: 6,
    //   title: "Web Banking App",
    //   img: "https://cdn.dribbble.com/users/6384483/screenshots/15468426/media/ce9479fa1f8dba3a4a49840d76e55e31.png?compress=1&resize=1200x900",
    // },
  ];
  
  export const mobilePortfolio = [
    {
      id: 1,
      title: "Mint Easy FInancial pedictions",
      img: "/assets/minteasy.png",
    },
    { 
      id: 2,
      title: "Mobile Rampa UI Design",
      img:
        "https://cdn.dribbble.com/users/5920881/screenshots/15463142/media/b5a460223798bd85d835710806e2f3dd.png?compress=1&resize=1200x900",
    },
    {
      id: 3,
      title: "Mobile E-commerce Design",
      img:
        "https://cdn.dribbble.com/users/1998175/screenshots/15459384/media/48ac2b43ebe81ba0866afea1383cc939.png?compress=1&resize=1200x900",
    },
    // {
    //   id: 4,
    //   title: "Mobile Relax App",
    //   img:
    //     "https://cdn.dribbble.com/users/2083704/screenshots/15468619/media/cd958306c7a772449e1ac23bd65ce506.png?compress=1&resize=1200x900",
    // },
    // {
    //   id: 5,
    //   title: "Mobile Hero Design",
    //   img:
    //     "https://cdn.dribbble.com/users/26642/screenshots/15462545/media/1a202ef5c8338b6f78634e6edc1ba350.png?compress=1&resize=1200x900",
    // },
    // {
    //   id: 6,
    //   title: "Mobile Banking App",
    //   img:
    //     "https://cdn.dribbble.com/users/1387827/screenshots/15466426/media/deb2dca6762cd3610321c98bfccb0b72.png?compress=1&resize=1200x900",
    // },
  ];
  
  export const designPortfolio = [
    {
      id: 1,
      title: "Design Social Media App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 2,
      title: "Design Rampa UI Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 3,
      title: "Design E-commerce Web Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    // {
    //   id: 4,
    //   title: "Design Relax Mobile App",
    //   img:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    // },
    // {
    //   id: 5,
    //   title: "Design Keser Web Design",
    //   img:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    // },
    // {
    //   id: 6,
    //   title: "Design Banking App",
    //   img:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    // },
  ];
  
  export const contentPortfolio = [
    {
      id: 1,
      title: "Content Social Media App",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 2,
      title: "Content Rampa UI Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    {
      id: 3,
      title: "Content E-commerce Web Design",
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    },
    // {
    //   id: 4,
    //   title: "Content Relax Mobile App",
    //   img:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    // },
    // {
    //   id: 5,
    //   title: "Content Keser Web Design",
    //   img:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    // },
    // {
    //   id: 6,
    //   title: "Content Banking App",
    //   img:
    //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKvdHn8GmPGCO0y3SJqNHACygpm0h9VycMHg&usqp=CAU",
    // },
  ];
  