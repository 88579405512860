import "./topbar.scss";
import { Person, Mail } from '@mui/material/Icon';




function Topbar({ menuOpen, setMenuOpen }) {
  return (
    <div className={"topbar " + (menuOpen && "active")}>
      <div className="wrapper">
        <div className="left">
          <a href="#intro" className="logo">Eitan Malkin.</a> 
          <div className="itemContainer">
            <div className="icon" />
            
            <span>+49 176 67027903</span> 
          </div>
          <div className="itemContainer">
            {/* <Mail className="icon" /> */}
            <span>eltonvansoylent@gmail.com</span>
          </div>
          </div>
        <div className="right">
          <div className="hamburger" onClick={()=> setMenuOpen(!menuOpen)}>
            <span className="line1"></span>
            <span className="line3"></span>
            <span className="line3"></span>

          
        </div>
        </div>
      </div>
    </div>


    
  );
}

export default Topbar;
