import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import "./contact.scss";
export default function Contact() {
  const [message, setMessage] = useState(false);
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage(true);
    



    emailjs
      .sendForm('service_54lpk4s', 'template_vna32yp', form.current, {
        publicKey: "2wkw8vV_x0W9TUjtf",
      })
      .then(
        (response) => {
          console.log('SUCCESS!', response);
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      e.target.reset();
  };

  return (
    <div className='contact' id="contact">
      <div className="left">
        <img src="assets/bird.jpg" alt="" />
      </div>
      <div className="right">
        <h2>Contact.</h2>
        <form ref={form} onSubmit={handleSubmit}>
          <input type="text" name="to_name" placeholder="Name" />
          <input type="email" name="email" placeholder="Email" />
          <textarea name="message" placeholder="Message"></textarea>
          <button type="submit">Send</button>
          {message && <span>Thanks, I'll get back to you promptly!</span>}
        </form>
      </div>
    </div>
  );
}
